import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Overview from './components/Overview';
import BenefitsSection from './components/BenefitsSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy'; 
import TermsOfService from './components/TermsOfService';
import SupportPage from './components/SupportPage';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #1a1a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

function App() {
  useEffect(() => {
    if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
      window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
    }
  }, []);

  return (
    <Router>
      <Container>
        <Header />
        <Routes>
          {/* Main landing page route */}
          <Route 
            path="/" 
            element={
              <>
                <Overview />
                <BenefitsSection />
                <ContactForm />
              </>
            } 
          />
          {/* Privacy policy route */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Terms of service route */}
          <Route path="/terms-of-service" element={<TermsOfService />} />
          {/* Support route */}
          <Route path="/support" element={<SupportPage />} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
}

export default App;
