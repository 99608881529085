import React from 'react';
import styled from 'styled-components';
import { FaCog, FaLock, FaTachometerAlt, FaMicrophoneAlt, FaDollarSign, FaWrench } from 'react-icons/fa';

const Section = styled.section`
  padding: 60px 20px;
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
  margin: 0;  /* Ensure no margin below */
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 40px;
  color: #2980b9;
`;

const BenefitsList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BenefitItem = styled.li`
  background-color: #ecf0f1;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;

  &:hover {
    background-color: #dfe6e9;
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #2980b9;
  margin-bottom: 20px;
`;

const TextWrapper = styled.div`
  text-align: center;
`;

function BenefitsSection() {
  return (
    <Section id="benefits">
      <Title>Benefits</Title>
      <BenefitsList>
        <BenefitItem>
          <IconWrapper><FaCog /></IconWrapper>
          <TextWrapper>
            <strong>Bespoke for Any Role</strong>
            <p>Configure system prompts and tool functions to build the perfect AI assistant.</p>
          </TextWrapper>
        </BenefitItem>

        <BenefitItem>
          <IconWrapper><FaLock /></IconWrapper>
          <TextWrapper>
            <strong>Privacy</strong>
            <p>All data is fully encrypted and easily deletable with one click.</p>
          </TextWrapper>
        </BenefitItem>

        <BenefitItem>
          <IconWrapper><FaMicrophoneAlt /></IconWrapper>
          <TextWrapper>
            <strong>Simple User Experience</strong>
            <p>Set the single button to push-to-talk or toggle mode for longer conversations.</p>
          </TextWrapper>
        </BenefitItem>

        <BenefitItem>
          <IconWrapper><FaTachometerAlt /></IconWrapper>
          <TextWrapper>
            <strong>Web Dashboard</strong>
            <p>Manage all your content and settings with a simple online dashboard.</p>
          </TextWrapper>
        </BenefitItem>

        <BenefitItem>
          <IconWrapper><FaDollarSign /></IconWrapper>
          <TextWrapper>
            <strong>Designed for Affordability</strong>
            <p>We aim to keep costs low, with further reductions as EchoBach scales.</p>
          </TextWrapper>
        </BenefitItem>

        {/* New benefit item for Dynamic Tool Selection */}
        <BenefitItem>
          <IconWrapper><FaWrench /></IconWrapper> {/* Tool icon */}
          <TextWrapper>
            <strong>Dynamic Tool Selection</strong>
            <p>Users can dynamically select the tool function calls most appropriate for each assistant use case.</p>
          </TextWrapper>
        </BenefitItem>
      </BenefitsList>
    </Section>
  );
}

export default BenefitsSection;
