import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import logo from '../assets/echobach_logo_192.png';

const HeaderContainer = styled.header`
  background-color: #2c3e50; /* Match with the Footer's background */
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #34495e; /* Optional: add a border for contrast */
  position: relative;
`;

const Logo = styled.img`
  width: 69px; 
  height: auto;
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    background-color: #2c3e50;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    padding: 20px 0;
  }
`;

const NavItem = styled.a`
  color: #bdc3c7; /* Match text color with Footer */
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background-color: #2980b9; /* Hover effect with primary accent color */
    color: #fff;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 15px 0;
    width: 100%;
  }
`;

const Hamburger = styled(FaBars)`
  display: none;
  color: #bdc3c7; /* Consistent text color */
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <Logo src={logo} alt="EchoBach"/>
      <Hamburger onClick={toggleMenu} />
      <Nav isOpen={isOpen}>
        <NavItem href="#overview">Overview</NavItem>
        <NavItem href="#benefits">Benefits</NavItem>
        <NavItem href="#contact-form">Order</NavItem>
        <NavItem href="#contact-form">Login</NavItem>
        <NavItem href="#contact-form">Contact</NavItem>
      </Nav>
    </HeaderContainer>
  );
}

export default Header;
