import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import productImage1 from '../assets/echobach_product_img_01.png';
import productImage2 from '../assets/echobach_product_img_02.png';
import toolsScreenshot from '../assets/echobach_screenshot_tools_01.png';

// Styled components for layout
const Section = styled.section`
  padding: 100px 20px;
  background-color: #f5f5f5; /* Light background for contrast */
  color: #333;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: #2c3e50; /* Same color as header and footer background */
  margin-bottom: 10px; /* Reduce space below the title */

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal; /* Make the subtitle less bold */
  color: #7f8c8d; /* Lighter color for the subtitle */
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ImageWrapper = styled.div`
  margin: 20px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
  height: 350px; /* Set a fixed height for the images */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px; /* Ensure wrapper has rounded corners */
  background-color: #fff; /* Adding background color to prevent flickering */
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure full image visibility */
  border-radius: 20px; /* Rounded corners applied to the image */
  display: ${({ visible }) => (visible ? 'block' : 'none')}; /* Hide inactive images */
  transition: opacity 1s ease-in-out;
`;

const Description = styled.p`
  font-size: 1.5rem;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.6;
  color: #7f8c8d;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0 20px;
  }
`;

const CallToAction = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  color: white;
  background-color: #2980b9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3498db;
  }
`;

function Overview() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [productImage1, productImage2, toolsScreenshot];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Section id="overview">
      <Title>EchoBach alpha</Title>
      <Subtitle>Your Customizable AI Assistant Device</Subtitle>
      
      <ImageWrapper>
        {images.map((img, index) => (
          <Image key={index} src={img} alt={`EchoBach Image ${index + 1}`} visible={index === currentImageIndex} />
        ))}
      </ImageWrapper>
      
      <Description>
        EchoBach alpha is a sleek, battery-powered AI assistant that you can fully customize. Whether it's for your kids, elderly relatives, or personal needs, 
        EchoBach is designed to adapt to any role with system prompts and tool function selections.
      </Description>
      <CallToAction>Learn More</CallToAction>
    </Section>
  );
}

export default Overview;
