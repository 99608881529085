import React from 'react';
import { Box, Typography, Container, Paper, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#00bcd4' },
    secondary: { main: '#ff4081' },
    background: { default: '#121212', paper: '#1e1e1e' },
    text: { primary: '#fff', secondary: '#aaa' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const SupportPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
          <Typography variant="h4" color="primary" gutterBottom>
            EchoBach Support
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Thank you for trying EchoBach! We appreciate you joining us on this journey to bring
            custom voice AI agents to life. EchoBach is currently in beta, so your feedback is
            invaluable in helping us improve.
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            EchoBach allows you to create and interact with personalized AI agents on various devices.
            To get started, please create an account at{' '}
            <Link href="https://app.echobach.com" color="primary" target="_blank" rel="noopener">
              app.echobach.com
            </Link>. After creating an account, log into the EchoBach iPhone app and start a conversation
            with the default agent. This initial interaction will register your iPhone as a “bach” in
            your EchoBach dashboard.
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Once your iPhone is registered, navigate to the “Bachs” section on the dashboard, where you’ll
            find the “iOS Devices” drawer. Here, you’ll see the unique ID of the iPhone you used. Selecting
            this device will open a detailed page where you can assign a friendly alias to your device.
            You can also create a custom AI agent in the “Agents” section, then go back to the device’s page
            and configure it to use this agent. Now, every interaction with the iPhone app will be with your
            newly assigned AI agent.
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            For support queries, please don’t hesitate to reach out. You can contact our founder directly at{' '}
            <Link href="mailto:jason@echobach.com" color="primary">
              jason@echobach.com
            </Link>.
          </Typography>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default SupportPage;
