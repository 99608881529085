import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #2c3e50;
  color: #bdc3c7;
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
  margin: 0;  /* Ensure no extra margin above */
`;

function Footer() {
  return (
    <FooterContainer>
      <p>© 2024 EchoBach, Inc. All rights reserved.</p>
      <Link to="/privacy-policy" style={{ color: '#00bcd4' }}>
        Privacy Policy
      </Link>
      <Link to="/terms-of-service" style={{ color: '#00bcd4', marginLeft: '10px' }}>
        Terms of Service
      </Link>
    </FooterContainer>
  );
}

export default Footer;
